import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import { toPlainText } from "../lib/helpers";

import PageHeader from "../components/contact-page-header";
import ContactMain from "../components/contact-main";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query SupportPageQuery {
    support: sanitySupport {
      title
      _rawContent
      formTitle
      sidebar {
        title
        content {
          _key
          label
          _rawContent
          removeBottomMargin
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }
  }
`;

const SupportPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const support = (data || {}).support;

  const title = support.seo?.title || support.title;
  const description = support.seo?.description || toPlainText(support._rawContent);
  const image = support.seo?.thumbnail;
  const autoSiteTitle = !support.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      {support.title && (<PageHeader title={support.title} description={support._rawContent} />)}
      {support && (<ContactMain title={support.formTitle} form="support" sidebar={support.sidebar} />)}
    </Layout>
  );
};

export default SupportPage;
